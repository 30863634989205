import React, { useState } from "react";
import Label from "../../label/label";
import "./date-time-input.css";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { useController, useFormContext, UseControllerReturn, FieldValues } from "react-hook-form";

const DateTimeInput = DateTimeInputController((props: DateTimeInputType) => {
  const [value, setValue] = useState("");
  function onChange(val: any) {
    props.controller?.field?.onChange(val);
    setValue(val);
  }
  const error = props.controller?.fieldState?.invalid;
  const errorMSg = props.controller?.fieldState?.error?.message;
  const success = props.controller?.fieldState?.isDirty && !error;
  return (
    <div
      style={props.containerStyle}
      className={`input-container ${props.inline ? "row" : ""} ${props.containerClass || ""}`}
    >
      <Label
        label={props.label}
        for={props.inputId}
        labelIcon={props.labelIcon}
        labelStyle={props.labelStyle}
        labelClass={props.labelClass}
      />
      <DateTimePicker
        disableClock={true}
        locale="fr"
        format="dd/MM/yyyy"
        dayPlaceholder="JJ"
        minDate={props.min}
        maxDate={props.max}
        onBlur={props.controller?.field?.onBlur}
        onSelect={props.controller?.field?.onBlur}
        monthPlaceholder="MM"
        yearPlaceholder="AAAA"
        calendarIcon={() => <img src={"/images/calendar.svg"} alt="" />}
        className={`form-control ${error ? "form-control-invalid" : ""} ${success ? "form-control-valid" : ""}`}
        onChange={onChange as any}
        value={value}
      />
      {error ? (
        <div className={`form-error-msg`}>
          <img
            style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
            src={"/images/error.svg"}
            alt=""
          />
          {errorMSg}
        </div>
      ) : null}
    </div>
  );
});
export default DateTimeInput;
function DateTimeInputController(Input: any) {
  return function (props: DateTimeInputType) {
    return InputControl(Input, props);
  };
}
function InputControl(Input: any, p: DateTimeInputType) {
  const formContext = useFormContext();
  let controller: any;
  if (p.name && formContext)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    controller = useController({
      name: p.name || "",
      rules: { required: p.required, validate: p.validate as any },
      defaultValue: p.value,
    });
  return <Input {...p} controller={controller} />;
}
export interface DateTimeInputType {
  label?: string;
  labelIcon?: string | { className: string; style?: React.CSSProperties };
  labelClass?: string;
  labelStyle?: React.CSSProperties;
  type?: "date" | "time" | "month" | "week" | "datetime-local";
  min?: Date;
  max?: Date;
  value?: Date;
  validate?: Function;
  step?: string;
  inputClass?: string;
  inputStyle?: React.CSSProperties;
  inputId?: string;
  name?: string;
  pattern?: string;
  form?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  controller?: UseControllerReturn<FieldValues, string>;
  required?: boolean | string;
  inline?: boolean;
  errorMsg?: string;
  errorMode?: "tooltip" | "feedback";
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  onChange?: Function;
}
