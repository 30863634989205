import React from "react";
import Structurelayout from "../../../../components/structureLayout/structurelayout";
import TextInput from "../../../../components/form/inputs/text-input/text-input";
import { useAppContext } from "../../../../context/app.context";
import Button from "../../../../components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import {  formatPhone, phonereg, sirenreg } from "../../../../utils/utils";
import PlacesInput from "../../../../components/form/inputs/places-input/places-inputs";

export default function StructurePage() {
  const { appInfos } = useAppContext();
  const user = appInfos.user;
  const methods = useForm({ mode: "onBlur" });
  function onSubmit() {}
  return (
    <Structurelayout header={Header()}>
      <FormProvider {...methods}>
        <div className="graycard">
          <div className="row">
            <h3>Votre structure</h3>
            <div className="col-md-4">
              <TextInput
                validate={(val: any) => (!sirenreg.test(val) ? "SIRET invalide" : true)}
                maxLength={14}
                value={user.structure.siret}
                name="siret"
                placeHolder="SIRET"
                label="SIRET"
              />
            </div>
            <div className="col-md-4">
              <PlacesInput
                value={user.structure.address}
                required
                placeHolder="Adresse postale"
                label="Adresse postale"
                name="address"
              />
            </div>
            <div className="col-md-4">
              <TextInput
                validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
                name="phone"
                value={user.structure.phone}
                format={formatPhone}
                maxLength={14}
                placeHolder="06.00.00.00.00"
                label="Téléphone de la structure"
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-10"></div>
          <div className="col">
            <Button onClick={methods.handleSubmit(onSubmit)} title="Enregistrer" />
          </div>
        </div>
      </FormProvider>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}
