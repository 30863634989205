import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import Button from "../../components/button/button";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

export default function RegisterSucessPage() {
  const location = useLocation();
  const params = location.state;
  const isAfterPay = !params?.role || params?.role == "Prestataire" || params?.role == "Structure";
  const [searchParams, setSearchParams] = useSearchParams();
  const fromMobileApp = params?.fromMobileApp || searchParams.get("mobile") == "yes";
  const navigate = useNavigate();
  function onLogin() {
    if (fromMobileApp) {
      window.location.href = "hyko://login";
    } else {
      navigate("/login");
    }
  }
  return (
    <LogoLayout hideBack={true}>
      <div className="successContainer">
        <svg className="successimg" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M63 38L43 58L33 48M48 93C23.1472 93 3 72.8528 3 48C3 23.1472 23.1472 3 48 3C72.8528 3 93 23.1472 93 48C93 72.8528 72.8528 93 48 93Z"
            stroke=""
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <h1  className="successTitle">{isAfterPay ? "Votre paiement" : "Votre inscription"}</h1>
        <span  className="subtitle">{"a bien été effectué !"}</span>
        <div className="successLink">
          <Button onClick={onLogin} title="Se connecter" />
        </div>
      </div>
    </LogoLayout>
  );
}
