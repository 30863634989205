import React from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
export default function TarifsPage() {
  return (
    <Structurelayout header={Header()}>
      <div>tarifsPage</div>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mes tarifs</h1>
    </div>
  );
}
