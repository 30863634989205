import React from "react";
import LogoLayout from "../../../components/logolayout/logolayout";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import DateTimeInput from "../../../components/form/inputs/datetime-input/date-time-input";
import { formatPhone, phonereg, showToast } from "../../../utils/utils";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import { capitalize } from "lodash";
import axiosInstance from "../../../config/axios";

export default function RegisterProfilPage() {
  const location = useLocation();
  const params = location.state;
  const redirect = window.history.state.idx == 0;
  const navigate = useNavigate();
  if (redirect) return <Navigate to={"/register"} replace />;
  async function onSubmit(form: any) {
    const { job, siret, role,fromMobileApp } = params;
    form = { job, siret, role, fromMobileApp,...form };
    try {
      const { data } = await axiosInstance.post("users/register/complete", form);
      navigate("/register/subscription", { state: { form, plans: data.plans } });
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  return (
    <LogoLayout onSubmit={onSubmit} progress={50}>
      <h1>Inscription</h1>
      <p className="subtitle">{params.role}</p>
      <h2 className="title">Mon profil</h2>
      <div>
        <TextInput name="last_name" required="Nom invalide" placeHolder="Nom" label="Nom" format={capitalize} />
        <TextInput
          name="first_name"
          required="Prénom invalide"
          placeHolder="Prénom"
          label="Prénom"
          format={capitalize}
        />
        <DateTimeInput
          max={new Date()}
          name="birth_date"
          required="Date de naissance invalide"
          label="Date de naissance"
        />
        <TextInput
          validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
          name="phone"
          format={formatPhone}
          maxLength={14}
          placeHolder="Téléphone"
          label="Téléphone"
        />
        <PlacesInput name="address" required={true} placeHolder="Adresse" label="Adresse, Code postal, Ville" />
      </div>
    </LogoLayout>
  );
}
