/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import TextInput, { TextInputType } from "../text-input/text-input";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useController, useFormContext, UseControllerReturn, FieldValues } from "react-hook-form";
const PlacesInput = PlacesInputController((props: PlacesInputType) => {
  const APIKEY = "AIzaSyDDCSAYIRrmNVgXSj8I_rBO7d1jGf1xPsI";
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: APIKEY,
  });
  const [val, setval] = useState("");
  const [places, setPlaces] = useState([]);
  useEffect(() => {
    if (props?.value?.address){
      setval(props.value?.address || "");
      props.controller?.field?.onChange(props?.value);
    }
  }, [props.value]);
  async function onChange(value: any) {
    props.controller?.field?.onChange(undefined);
    setval(value);
    if (!value) {
      setPlaces([]);
      return;
    }
    try {
      await getPlacePredictions({ input: value, componentRestrictions: { country: "fr" } });
      const addresses: any = placePredictions;
      setPlaces(
        addresses.map((i: any) => {
          return { label: i.description, value: i.place_id };
        })
      );
      if (props.onData) props.onData(addresses);
      if (props.onChange) props.onChange(value);
    } catch (e) {
      if (props.onData) props.onData([]);
    }
  }

  async function onSelectItem(item: any) {
    try {
      const placeId = item.value;
      placesService?.getDetails(
        {
          placeId: placeId,
        },
        (place: any) => {
          const adress = {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            street: place.address_components.find((elt: any) => elt.types.indexOf("route") != -1)?.long_name,
            streetNumber: place.address_components.find((elt: any) => elt.types.indexOf("street_number") != -1)
              ?.long_name,
            country: place.address_components.find((elt: any) => elt.types.indexOf("country") != -1)?.short_name,
            zipCode: place.address_components.find((elt: any) => elt.types.indexOf("postal_code") != -1)?.short_name,
            administrative_area_level_1: place.address_components.find(
              (elt: any) => elt.types.indexOf("administrative_area_level_1") != -1
            )?.long_name,
            administrative_area_level_2: place.address_components.find(
              (elt: any) => elt.types.indexOf("administrative_area_level_2") != -1
            )?.long_name,
            address: place.formatted_address,
          };
          props.controller?.field?.onChange(adress);
          props.controller?.field?.onBlur();
          if (props.onChooseAddress) {
            props.onChooseAddress(adress);
          }
        }
      );
    } catch (e) {
      /* empty */
    }
  }
  const error = props.controller?.fieldState?.invalid;
  const errorMSg = props.controller?.fieldState?.error?.message;
  const success = props.controller?.fieldState?.isDirty && !error;
  return (
    <TextInput
      onBlur={props.controller?.field?.onBlur}
      {...props}
      controller={undefined}
      name={undefined}
      onSelectItem={onSelectItem}
      datalist={places}
      value={val}
      errorMsg={errorMSg}
      className={`${error ? "form-control-invalid" : ""} ${success ? "form-control-valid" : ""}`}
      onChange={onChange}
    />
  );
});
export default PlacesInput;
function PlacesInputController(Input: any) {
  return function (props: PlacesInputType) {
    return InputControl(Input, props);
  };
}
function InputControl(Input: any, p: PlacesInputType) {
  const formContext = useFormContext();
  let controller: any;
  if (p.name && formContext)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    controller = useController({
      name: p.name,
      rules: {
        validate: (val: any) => {
          if (p.required) {
            return !isNaN(val?.latitude) ? true : "Adresse invalide";
          }
          return !val || !isNaN(val?.latitude) ? true : "Adresse invalide";
        },
      },
      defaultValue: p?.value,
    });
  return <Input {...p} controller={controller} />;
}

interface PlacesInputType extends TextInputType {
  language?: string;
  countries?: string[];
  onData?: Function;
  controller?: UseControllerReturn<FieldValues, string>;
  onChooseAddress?: Function;
  showlocationBtn?: boolean;
  value?:any;
}
