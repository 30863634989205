import React from "react";
import Structurelayout from "../../../../components/structureLayout/structurelayout";

export default function SubscriptionPage() {
  return (
    <Structurelayout header={Header()}>
      <div className="graycard">
        <div className="row">
          <h3>Votre abonnement</h3>
          
        </div>
      </div>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}
