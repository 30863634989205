import { toast } from "react-toastify";

export function formatPhone(text: any) {
  const cleanedInput = text?.replace(/\D/g, "");
  if (cleanedInput) {
    const formattedText = cleanedInput.match(/.{1,2}/g).join(".");
    return formattedText;
  } else return "";
}



export function formatCode(inputText: any) {
  const cleanedInput = inputText.replace(/\D/g, "");
  const formattedText = cleanedInput.split("").join(" ");
  return formattedText;
}
export const showToast = (content: string, type?: any, position?: any) =>
  toast(content, { autoClose: 2500, type: type || "error", position });

export const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const phonereg = /^(06|07)\d{8}$/;
export const passwordReg =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹/])[A-Za-z\d~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹/]{8,}$/;
export const sirenreg = /^\d{14}$/;
