import React, { useState } from "react";
import "./structurelayout.css";
import { Link, useLocation } from "react-router-dom";
export default function Structurelayout(props: any) {
  const router = useLocation();
  function linkStatus(link: any) {
    return router.pathname.indexOf(link) != -1 ? "-active" : "";
  }
  return (
    <div className="structurelayout">
      <img src="/images/ele1.png" alt="" className="backimg" />
      <div className="header">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <Link className="navbar-brand" to="/structure">
            <a
              className="toggle"
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              role="button"
              aria-controls="offcanvasExample"
            >
              <img className="toggle" src="/images/menu.svg" alt="" />
            </a>
            <img width={24} height={24} src="/images/Logo.svg" alt="" />
          </Link>
          <div className="navbar" id="navbarText">
            <ul className="nav ">
              <li className="nav-item  me-5 ">
                <Link className={`link${linkStatus("/structure/gestion")}`} to="/structure/gestion">
                  <img className="linkimg" src={`/images/gestion${linkStatus("/structure/gestion")}.svg`} alt="" />
                  Gestion
                </Link>
              </li>
              <li className="nav-item  me-5">
                <Link className={`link${linkStatus("/structure/clients")}`} to="/structure/clients">
                  <img className="linkimg" src={`/images/clients${linkStatus("/structure/clients")}.svg`} alt="" />
                  Clients
                </Link>
              </li>
              <li className="nav-item  me-5">
                <Link className={`link${linkStatus("/structure/tarifs")}`} to="/structure/tarifs">
                  <img className="linkimg" src={`/images/tarifs${linkStatus("/structure/tarifs")}.svg`} alt="" />
                  Tarifs
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`link${linkStatus("/structure/profil")}`} to="/structure/profil/account">
                  <img className="linkimg" src={`/images/profil${linkStatus("/structure/profil")}.svg`} alt="" />
                  Profil
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <div className="headercontent">
          {props.header}
          {router.pathname.indexOf("/profil") != -1 ? (
            <div className="profilheader">
              <Link className={`link1${linkStatus("/profil/account")} me-3`} to="/structure/profil/account">
                Compte
              </Link>
              <Link className={`link1${linkStatus("/profil/manager")} me-3`} to="/structure/profil/manager">
                Gérant
              </Link>
              <Link className={`link1${linkStatus("/profil/structure")} me-3`} to="/structure/profil/structure">
                Structure
              </Link>
              <Link className={`link1${linkStatus("/profil/subscription")} me-3`} to="/structure/profil/subscription">
                Abonnement
              </Link>
            </div>
          ) : null}
        </div>
      </div>
      <div className="content">{props.children}</div>
      <div className="footer">
        <Link className={`link${linkStatus("/conditions")}   me-5`} to="/conditions">
          Conditions générale
        </Link>
        <Link className={`link${linkStatus("/cgu")}  me-5`} to="/cgu">
          CGV/CGU
        </Link>
        <Link className={`link${linkStatus("/mentions")}  me-5`} to="/mentions">
          Mentions légales
        </Link>
      </div>
      <Sidebar />
    </div>
  );
}

function Sidebar() {
  const router = useLocation();
  const [showprofil, setshowprofil] = useState(false);
  function linkStatus(link: any) {
    return router.pathname.indexOf(link) != -1 ? "-active" : "";
  }
  return (
    <div className="offcanvas" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-body">
        <div className="sidebar">
          <img width={24} height={24} src="/images/Logo.svg" alt="" />

          <div className="sidebartop">
            <Link className={`link${linkStatus("/structure/gestion")}`} to="/structure/gestion">
              <img className="linkimg" src={`/images/gestion${linkStatus("/structure/gestion")}.svg`} alt="" />
              Gestion
            </Link>
            <Link className={`link${linkStatus("/structure/clients")} mt-40`} to="/structure/clients">
              <img className="linkimg " src={`/images/clients${linkStatus("/structure/clients")}.svg`} alt="" />
              Clients
            </Link>
            <Link className={`link${linkStatus("/structure/tarifs")} mt-40`} to="/structure/tarifs">
              <img className="linkimg" src={`/images/tarifs${linkStatus("/structure/tarifs")}.svg`} alt="" />
              Tarifs
            </Link>
            <Link
              className={`link${linkStatus("/structure/profil")} mt-40`}
              to="/structure/profil/account"
              onClick={e => {
                e.preventDefault();
                setshowprofil(true);
              }}
            >
              <img className="linkimg" src={`/images/profil${linkStatus("/structure/profil")}.svg`} alt="" />
              Profil
            </Link>
            {showprofil || router.pathname.indexOf("/profil") != -1 ? (
              <>
                <Link className={`link1${linkStatus("/profil/account")} mt-20`} to="/structure/profil/account">
                  Compte
                </Link>
                <Link className={`link1${linkStatus("/profil/manager")} `} to="/structure/profil/manager">
                  Gérant
                </Link>
                <Link className={`link1${linkStatus("/profil/structure")} `} to="/structure/profil/structure">
                  Structure
                </Link>
                <Link className={`link1${linkStatus("/profil/subscription")}`} to="/structure/profil/subscription">
                  Abonnement
                </Link>
              </>
            ) : null}
          </div>
          <div className="sidebarbottom">
            <Link className={`link${linkStatus("/conditions")}`} to="/conditions">
              Conditions générale
            </Link>
            <Link className={`link${linkStatus("/cgu")} mt-20`} to="/cgu">
              CGV/CGU
            </Link>
            <Link className={`link${linkStatus("/mentions")} mt-20`} to="/mentions">
              Mentions légales
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
