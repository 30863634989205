import React from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
export default function ClientsPage() {
  return (
    <Structurelayout header={Header()}>
      <div>ClientsPage</div>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mes clients</h1>
    </div>
  );
}
