import React from "react";
import Structurelayout from "../../../../components/structureLayout/structurelayout";
import TextInput from "../../../../components/form/inputs/text-input/text-input";
import { useAppContext } from "../../../../context/app.context";
import Button from "../../../../components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import { emailReg, formatPhone, phonereg } from "../../../../utils/utils";

export default function ManagerPage() {
  const { appInfos } = useAppContext();
  const user = appInfos.user;
  const methods = useForm({ mode: "onBlur" });
  function onSubmit(form: any) {}
  return (
    <Structurelayout header={Header()}>
      <FormProvider {...methods}>
        <div className="graycard">
          <div className="row">
            <h3>Votre gérant</h3>
            <div className="col-md-4">
              <TextInput required="Nom invalide" value={user.manager.first_name} label="Nom" name="lastName" />
            </div>
            <div className="col-md-4">
              <TextInput required="Prénom invalide" value={user.manager.last_name} label="Prénom" name="firstName" />
            </div>
            <div className="col-md-4">
              <TextInput
                name="email"
                autocomplete="off"
                type="email"
                value={user.manager.email}
                label="E-mail"
                validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
              />
            </div>
            <div className="col-md-4">
              <TextInput
                validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
                name="phone"
                value={user.manager.phone}
                format={formatPhone}
                maxLength={14}
                placeHolder="06.00.00.00.00"
                label="Téléphone"
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-10"></div>
          <div className="col">
            <Button onClick={methods.handleSubmit(onSubmit)} title="Enregistrer" />
          </div>
        </div>
      </FormProvider>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}
