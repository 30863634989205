import "./text-input.css";
import React, { useEffect, useState } from "react";
import Label from "../../label/label";
import { useController, useFormContext, FieldValues, UseControllerReturn } from "react-hook-form";

const TextInput = TextInputController((props: TextInputType) => {
  const [value, setValue] = useState(props.value || "");
  const [type, setType] = useState(props.type || "text");
  const [showdropdown, setshowdropdown] = useState(false);
  useEffect(() => {
    setValue(props.value || "");
    const type = props.type || "text";
    setType(type);
  }, [props.type, props.value]);

  function onChange(event: any) {
    props.controller?.field?.onChange(event);
    setValue(event.target.value);
    if (props.onChange) props.onChange(event.target.value);
  }

  function onPasswordIconClick() {
    setType((old) => (old === "password" ? "text" : "password"));
  }

  function onBlur() {
    props.controller?.field?.onBlur();
    setTimeout(() => {
      setshowdropdown(false);
    }, 400);
    if (props.onBlur) props.onBlur();
  }
  function onSelect(elt: any) {
    props.controller?.field?.onChange(elt);
    setValue(elt.label);
    if (props.onChange) props.onChange(elt.label);
    if (props.onSelectItem) props.onSelectItem(elt);
    setshowdropdown(false);
  }
  const error = props.controller?.fieldState?.invalid;
  const errorMSg = props.controller?.fieldState?.error?.message;
  const success = props.controller?.fieldState?.isDirty && !error;
  const helpers: any = props.helper && !Array.isArray(props.helper) ? [props.helper] : props.helper;
  const errors: any = props.error && !Array.isArray(props.error) ? [props.error] : props.error;
  return (
    <div style={props.containerStyle} className={`mb3 input-container`}>
      {props.label !== undefined && !props.floatingLabel && <Label label={props.label} for={props.name} />}
      <GroupContainer showPassword={props.showPassword}>
        <input
          className={`form-control ${props.type == "password" || props.rightIcon ? "form-control-icon" : ""} ${
            error ? "form-control-invalid" : ""
          } ${success ? "form-control-valid" : ""} ${props.className ? props.className : ""}`}
          type={type || "text"}
          id={props.name}
          name={props.name}
          onFocus={() => setshowdropdown(true)}
          autoComplete={props.autocomplete}
          pattern={props.pattern || undefined}
          value={props.format ? props.format(value) : value}
          onBlur={onBlur}
          required={props.required ? true : false}
          placeholder={props.placeHolder}
          maxLength={props.maxLength}
          readOnly={props.readonly || false}
          disabled={props.disabled || false}
          onChange={onChange}
          aria-describedby={props.helper ? "helper" + props.name : undefined}
        />
        {props.type === "password" && props.showPassword && (
          <div className="input-group-text  input-group-icon">
            <button className="iconBtn" onClick={onPasswordIconClick}>
              {type === "password" ? <img src={"/images/eye.svg"} alt="" /> : <img src={"/images/eye.svg"} alt="" />}
            </button>
          </div>
        )}
        {props.type != "password" && props.rightIcon && (
          <div className="input-group-text iconContainer">{props.rightIcon}</div>
        )}
      </GroupContainer>
      {(props.datalist?.length as any) > 0 && showdropdown && (
        <div className="dropdown">
          <ul className={`dropdown-menu show`}>
            {(props.datalist as any).map((elt: any, ind: any) => (
              <li key={ind}>
                <button
                  className={`dropdown-item ${elt.disabled ? "disabled" : ""} `}
                  style={{ overflow: "hidden" }}
                  onClick={() => onSelect(elt)}
                  type="button"
                >
                  {elt.label || elt.value}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {(props.errorMsg || error) &&
        (errors ? (
          errors.map((elt: any, ind: any) => (
            <div key={ind} className={`form-error-msg`}>
              <img
                style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
                src={"/images/error.svg"}
                alt=""
              />
              {elt}
            </div>
          ))
        ) : (
          <div className={`form-error-msg`}>
            <img
              style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
              src={"/images/error.svg"}
              alt=""
            />
            {props.errorMsg || errorMSg}
          </div>
        ))}
      {!error && helpers && (
        <div style={{ marginTop: 7 }}>
          {helpers.map((elt: any, ind: any) => (
            <div key={ind} id={"helper" + props.name} className={`form-text`}>
              <img
                style={{ marginInlineEnd: 5, width: 15, marginTop: -2, marginRight: 7 }}
                src={"/images/errorgray.svg"}
                alt=""
              />
              {errorMSg}
              {elt}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});
export default TextInput;

function GroupContainer(props: any) {
  return props.showPassword ? (
    <div className={`input-group ${props.className}`}>{props.children}</div>
  ) : (
    <>{props.children}</>
  );
}

function TextInputController(Input: any) {
  return function (props: TextInputType) {
    return InputControl(Input, props);
  };
}

function InputControl(Input: any, p: TextInputType) {
  const formContext = useFormContext();
  let controller: any;
  if (p.name && formContext) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    controller = useController({
      name: p.name || "",
      rules: { required: p.required, validate: p.validate as any },
      defaultValue: p.value,
    });
  }
  return <Input {...p} controller={controller} />;
}

export interface TextInputType {
  type?: "password" | "email" | "text" | "number" | "int" | "positive-int" | "positive-number" | "search" | "tel";
  label?: string; //done
  helper?: string | string[]; //done
  error?: string | string[];
  errorMsg?: string;
  className?: string;
  pattern?: string;
  datalist?: any[];
  autocomplete?: string;
  validate?: Function;
  onBlur?: Function;
  rightIcon?: any;
  value?: string; //done
  floatingLabel?: boolean;
  showPassword?: boolean;
  inputIcon?: string;
  placeHolder?: string;
  format?: Function;
  onSelectItem?: Function;
  name?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean | string;
  controller?: UseControllerReturn<FieldValues, string>;
  maxLength?: number;
  onChange?: Function;
  containerStyle?: React.CSSProperties; //done
  onKeyPress?: Function;
}
